import eStateTypes from './eStateTypes';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';

export default {
  EET_ERROR_MANAGER__SHOW_ERROR: 'errorManager:showError', //Engine
  EET_SERVER_MANAGER__UPDATE_BALANCE: 'dataProvider:updateBalance',
  EET_GAME__STARTED: 'game:gameStarted', //Engine
  EET_PRELOADER_CONTROLLER__UPDATE_PROGRESS: 'preloaderController:progress', //Engine
  EET_DATA_UPDATED: 'model:getServerData',
  EET_BET_CHANGED: 'model:betChanged',

  EET_SPIN_CLICK: 'ui:spinClick',
  EET_UI_CHANGE_BET: 'ui:changeBet',
  EET_SETTINGS__CLICK: 'ui:settingsClick',
  EET_PAYTABLE__CLICK: 'ui:paytableClick',
  EET_PAYTABLE__CHANGE_PAGE: 'paytable:changePage',
  EET_PAYTABLE__BACK_CLICK: 'paytable:backClick',

  EET_SETTINGS__SOUND_MUTE: 'gameSettings:soundMute', //Engine
  EET_SETTINGS__MUSIC_MUTE: 'gameSettings:musicMute', //Engine

  EET_PURCHASE_BTN_CLICK: 'ui:openPurchaseClick',
  EET_PURCHASE__CLOSE: 'ui:closePurchase',

  EET_BUY_BONUS: 'bonusGame:buy',
  EET_PURCHASE_CONFIRM: 'bonusGame:confirm',

  EET_REELS_STOPPED: 'reelsContainer:reelsStopped',

  EET_SPACIAL_SYMBOL_HIT_THE_BAR: 'specilalSymbol: hitTheBar',

  EET_STATE_MANAGER__STATE_CHANGED: 'stateManager.stateChanged', //Engine
  EET_WINNINGS_CONTROLLER__ALL_WINNINGS_COMPLETE:
    'winningsController:allWinningsComplete',
  EET_MODEL__LINES_CHANGED: 'model:linesChanged', //Engine

  EET_FREESPINS_START__SHOWN_UP: 'freespinsStart: shownUp',
  EET_FREESPINS_END__SHOWN_UP: 'freespinsEnd: shownUp',
  EET_FREESPINS_END__ON_COMPLETE: 'freespinsEnd: onComplete',
  EET_FREESPINS_START__ON_COMPLETE: 'freespinsStart: onComplete', //Engine
  EET_WINNINGS_CONTROLLER__SLOW_WIN: 'winningsController:slowWin', //Engine
  EET_WINNINGS_CONTROLLER__START_WINNINGS:
    'winningsController:startShowWinnings', //Engine
  EET_START_SCREEN__SHOW: 'startScreen:show',
  EET_START_SCREEN__HIDE: 'startScreen:hide',
  EET_BIG_WIN__SKIP: 'bigWinContainer:stopState', //Engine
  EET_BIG_WIN__SKIP_COMPLETED: 'bigWinContainer:nextState', //Engine

  EET_INIT_STATE__END: `stateEnd:${eStateTypes.EST_INIT}`, //Engine
  EET_IDLE_STATE__START: `stateStart:${eStateTypes.EST_IDLE}`,
  EET_START_SCREEN__STATE__START: `stateStart:${eStateTypes.EST_START_SCREEN}`,
  EET_START_SCREEN__STATE__END: `stateEnd:${eStateTypes.EST_START_SCREEN}`,
  EET_ROLLING_STATE__START: `stateStart:${eStateTypes.EST_REQUEST_ROLLING}`,
  EET_SHOW_WIN_STATE__START: `stateStart:${eStateTypes.EST_SHOW_WIN}`,
  EET_STOP_WIN_STATE__START: `stateStart:${eStateTypes.EST_STOP_WIN}`,
  EET_STOP_WIN_STATE__END: `stateEnd:${eStateTypes.EST_STOP_WIN}`,
  EET_FREESPIN_IN_STATE__START: `stateStart:${eStateTypes.EST_FREE_SPINS_IN}`,
  EET_FREESPIN_IN_STATE__END: `stateEnd:${eStateTypes.EST_FREE_SPINS_IN}`,
  EET_FREESPIN_OUT_STATE__START: `stateStart:${eStateTypes.EST_FREE_SPINS_OUT}`,
  EET_FREESPIN_OUT_STATE__END: `stateEnd:${eStateTypes.EST_FREE_SPINS_OUT}`,
  EET_SHOW_USER_INTERFACE: 'userInterface:show',
  EET_HIDE_USER_INTERFACE: 'userInterface:hide',

  EET_FOURTH_REEL_STOPPED: 'reelsContainer:fourthReelStopped',
  EET_EXTRA_REELS_STOPPED: 'ExtraReelsContainer:reelsStopped',

  ...eBaseEventTypes,
};
