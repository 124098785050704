import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../animations/animationTypes';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../enums/eSoundTypes';

export default class ControllerAntisipator extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this._initAnimations();
  }
  checkAntisipator(data) {
    if (
      (!EntryPoint.GameModel.bonusGameEnabled ||
        EntryPoint.GameModel.bonusGameTriggered) &&
      data[0].find((simbolId) => simbolId === 9) &&
      data[1].find((simbolId) => simbolId === 9)
    ) {
      this.isAntisipator = true;
    }
  }

  tryStartAntisipator() {
    if (!this.animation) return;
    if (this.isAntisipator) {
      GlobalDispatcher.dispatch('antisipator: start');
      SoundManager.play(eSounds.EST_REEL_ANTISIPATOR.soundName, eSounds.EST_REEL_ANTISIPATOR.volume, false, eSounds.EST_REEL_ANTISIPATOR.group);
      this.animation.visible = true;
    }
  }

  tryStopAntisipator() {
    if (!this.animation) return;
    if (this.isAntisipator) {
      GlobalDispatcher.dispatch('antisipator: stop', this);
      SoundManager.stop(eSounds.EST_REEL_ANTISIPATOR.soundName);
      SoundManager.play(
        eSounds.EST_REEL_ANTISIPATOR_TILE.soundName,
        eSounds.EST_REEL_ANTISIPATOR_TILE.volume,
        false,
        eSounds.EST_REEL_ANTISIPATOR_TILE.group
      );
      this.animation.visible = false;
      this.isAntisipator = false;
    }
  }

  async _initAnimations() {
    const animation = await animationCreator.createAnimationWithDelayedLoading(
      animationTypes.ANTISIPATOR
    );
    await animationCreator.uploadToGPU(animation);
    this.animation = animation;
    this.animation.state.setAnimation(0, 'antisipator', true);
    this.animation.visible = false;
    this.addChild(this.animation);
  }
}
