export default {
  SYMBOLS: 'symbols',
  BIG_WIN: 'bigWin_spine',
  NEAR_WIN: 'neat_win',
  PURCHASE_BUTTON: 'purchase_button',
  PURCHASE: 'purchase_animation',
  BACKGROUND: 'background',
  JACKPOT: 'jackpot',
  JACKPOT_PANEL: 'jackpot_panel',
  ANTISIPATOR: 'antisipator',
}
