import FlashLib from 'flashlib_onlyplay';

import BaseCheckBox from 'Engine/base/ui/BaseCheckBox';

import Main from './Main';

import ReelsContainer from './reels/ReelsContainer';
import OverlayContainer from 'Engine/containers/overlay/BaseOverlayContainer';

import Symbol from './symbols/Symbol';

// import SlotLinesContainer from "./lines/SlotLinesContainer";
// import WinLineAnimated from "./lines/WinLineAnimated";

import SettingsContainer from 'Engine/containers/setting/BaseSettingsContainer';

import StartScreenContainer from './startScreen/startScreen';

import UiContainer from './ui/BaseGameUiContainer';
import MaxBetButton from './ui/MaxBetButton';

import UiMobileContainer from './ui/BaseGameUiContainer';

import InfoPopupContainer from 'Engine/containers/InfoPopupContainer';

import PaytableContainer from './paytable/PaytableContainer';
import PaytableContent from './paytable/PaytableContent';
import PaytableControls from './paytable/PaytableControls';
import SymbolAnimation from './symbols/SymbolAnimation';
import ControllerJackpotPanels from './jackpot/JackpotPanels/ControllerJackpotPanels';
import ControllerPanel from './jackpot/jackpotGame/ControllerPanel';
import ControllerJackpotGame from './jackpot/jackpotGame/ControllerJackpotGame';
import ControllerStones from './jackpot/jackpotGame/ControllerStones';
import ControllerJackpotGamePlate from './jackpot/jackpotGame/ControllerJackpotGamePlate';
import ControllerJackpotGameField from './jackpot/jackpotGame/ControllerJackpotGameField';
import ControllerJackpotGamePanels from './jackpot/jackpotGame/ControllerJackpotGamePanels';
import PopupWonJackpotGame from './jackpot/jackpotGame/PopupWonJackpotGame';
import PopupWonJackpot from './jackpot/jackpotGame/PopupWonJackpot';
import PopupWin from './popups/popupWin/PopupWin';
import BackgroundContainer from './background/BackgroundContainer';
import ControllerStone from './jackpot/jackpotGame/ControllerStone';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import TotalWinContainer from './ui/TotalWinContainer';
import ControllerBonusGameStart from './popups/freespins/ControllerBonusGameStart';
import ControllerBonusGameEnd from './popups/freespins/ControllerBonusGameEnd';
import ControllerSlot from './slot/ControllerSlot';
// import ControllerPopupsBgAnimation from './background/ControllerPopupsBgAnimation';
import ControllerJackpotPanel from './jackpot/JackpotPanels/ControllerJackpotPanel';
import RespinsContainer from './ui/RespinsContainer';
import ControllerAntisipator from './reels/ControllerAntisipator';
import BalanceContainer from './ui/BalanceContainer';
import WinContainer from './ui/WinContainer';
import BetSelectorContainer from './ui/BetSelectorContainer';
import BaseGameButton from './ui/BaseGameButton';
import BaseGameUiContainer from './ui/BaseGameUiContainer';

FlashLib.registerClass('Button', BaseGameButton);
FlashLib.registerClass('CheckBox', BaseCheckBox);

FlashLib.registerClass('Main', Main);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('Symbol', Symbol);
// FlashLib.registerClass('WinLineAnimated', WinLineAnimated);
// FlashLib.registerClass('SlotLinesContainer', SlotLinesContainer);
FlashLib.registerClass('SettingsContainer', SettingsContainer);

FlashLib.registerClass('StartScreenContainer', StartScreenContainer);

FlashLib.registerClass('UiContainer', UiContainer);
FlashLib.registerClass('BalanceContainer', BalanceContainer);
FlashLib.registerClass('WinContainer', WinContainer);
FlashLib.registerClass('BetContainer', BetSelectorContainer);
FlashLib.registerClass('MaxBetButton', MaxBetButton);

FlashLib.registerClass('UiMobileContainer', UiMobileContainer);

FlashLib.registerClass('InfoPopupContainer', InfoPopupContainer);

FlashLib.registerClass('OverlayContainer', OverlayContainer);

FlashLib.registerClass('PaytableContainer', PaytableContainer);
FlashLib.registerClass('PaytableContent', PaytableContent);
FlashLib.registerClass('PaytableControls', PaytableControls);
FlashLib.registerClass('SymbolAnimation', SymbolAnimation);
FlashLib.registerClass('ControllerJackpotPanels', ControllerJackpotPanels);
FlashLib.registerClass('ControllerPanel', ControllerPanel);
FlashLib.registerClass('ControllerJackpotGame', ControllerJackpotGame);
FlashLib.registerClass('ControllerStones', ControllerStones);
FlashLib.registerClass(
  'ControllerJackpotGamePlate',
  ControllerJackpotGamePlate
);
FlashLib.registerClass(
  'ControllerJackpotGameField',
  ControllerJackpotGameField
);
FlashLib.registerClass(
  'ControllerJackpotGamePanels',
  ControllerJackpotGamePanels
);
FlashLib.registerClass('ControllerStone', ControllerStone);
FlashLib.registerClass('PopupWonJackpotGame', PopupWonJackpotGame);
FlashLib.registerClass('PopupWonJackpot', PopupWonJackpot);
FlashLib.registerClass('PopupWin', PopupWin);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('ControllerBonusGameStart', ControllerBonusGameStart);
FlashLib.registerClass('ControllerBonusGameEnd', ControllerBonusGameEnd);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
// FlashLib.registerClass('ControllerPopupsBgAnimation', ControllerPopupsBgAnimation);
FlashLib.registerClass('ControllerJackpotPanel', ControllerJackpotPanel);
FlashLib.registerClass('RespinsContainer', RespinsContainer);
FlashLib.registerClass('TotalWinContainer', TotalWinContainer);
FlashLib.registerClass('ControllerAntisipator', ControllerAntisipator);
