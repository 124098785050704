import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eSymbolIndex from '../symbols/eSymbolIndex';
import SoundManager from 'Engine/soundManager/SoundManager';
import { gsap } from 'gsap';
import { eSounds } from '../enums/eSoundTypes';

export default class ControllerFlyCoinsValue extends PIXI.Container {
  constructor(ControllerReels) {
    super();
    this.ControllerReels = ControllerReels;
    this.addListeners();
  }
  addListeners() {
    GlobalDispatcher.add(
      'uiContainer:winContainerData',
      this.writeTotalWinContainerPosition,
      this
    );
    GlobalDispatcher.add('stateStart:flyValueState', this.flyTextFields, this);
  }
  flyTextFields() {
    const valueTextFields = [];
    this.ControllerReels.reels.forEach((reel, reelIndex) => {
      for (let i = 1; i < reel.symbols.length - 1; i++) {
        const symbol = reel.symbols[i];
        if (symbol.id === eSymbolIndex.ESI_COIN) {
          valueTextFields.push(symbol.valueTextField);
          symbol.startAnimation();
        }
      }
    });
    if (valueTextFields.length === 0) {
      GlobalDispatcher.dispatch('fly: complete');
      return;
    } else {
      SoundManager.play(eSounds.EST_FLY_COIN_VALUE.soundName, eSounds.EST_FLY_COIN_VALUE.volume, false, eSounds.EST_FLY_COIN_VALUE.group);
    }
    valueTextFields.forEach((textField, index) =>
      this._animateValueFly(textField, index === valueTextFields.length - 1)
    );
  }

  _animateValueFly(textField, isLastField) {
    const newTextField = new PIXI.Text(textField.text, textField.style);
    newTextField.anchor.set(0.5);
    const newTextFieldPosition = this.toLocal({ x: 0, y: 0 }, textField);
    newTextField.x = newTextFieldPosition.x + newTextField.width / 2;
    newTextField.y = newTextFieldPosition.y + newTextField.height / 2;
    this.addChild(newTextField);
    gsap.to(newTextField, {
      x: this._totalWinContainerData.x,
      y: this._totalWinContainerData.y,
      duration: 1,
      onComplete: () => {
        newTextField.destroy();
        if (isLastField) GlobalDispatcher.dispatch('fly: complete');
      },
    });
    const scaleEnd = newTextField.height / this._totalWinContainerData.height;
    gsap.to(newTextField.scale, { x: scaleEnd, y: scaleEnd, duration: 1 });
    gsap.to(newTextField, { alpha: 0, delay: 0.4, duration: 0.2 });
  }

  writeTotalWinContainerPosition({ params: totalWinContainer }) {
    const totalWinContainerData = this.toLocal(
      { x: totalWinContainer.width / 2, y: totalWinContainer.height / 4 + 5 },
      totalWinContainer
    );
    totalWinContainerData.width = totalWinContainer.width;
    totalWinContainerData.height = totalWinContainer.height;
    this._totalWinContainerData = totalWinContainerData;
  }
}
