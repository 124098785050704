import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import eBigWinDefaultCoefs from './eBigWinDefaultCoefs';
import eBigWinTypes from './eBigWinTypes';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import ServerManager from '../server/ServerManager';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();
    this._respins = 0;
    this._bonusGameTotalWin = 0;
    this._bonusGameTriggered = false;

    GlobalDispatcher.addOnce('model:getServerData',() => {
      this._winRankCoef = {
        [eBigWinTypes.EBWT_BIG_WIN]:  OPWrapperService.config.minWinRankCoef ? OPWrapperService.config.minWinRankCoef : eBigWinDefaultCoefs.EBWC_BIG_WIN,
        [eBigWinTypes.EBWT_MEGA_WIN]: eBigWinDefaultCoefs.EBWC_MEGA_WIN
    };
    }, this);
  }

  init () {
    super.init();
    ServerManager.onInit();
  }


  setNewData(data) {
    super.setNewData(data);

    if (data.jackpotGame) {
      this._winJackpotType = data.jackpotGame.winJackpotType;
      this._jackpotWinAmount = data.jackpotGame.jackpotWinAmount;
      if (this._jackpotWinAmount) window.OPWrapperService.ControllerStatistic.win = this._jackpotWinAmount;
      this._jackpotField = data.jackpotGame.field || [[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1],[-1,-1,-1]];
      this._jackpotResults = data.jackpotGame.results || [0,0,0,0,0];
      this._jackpotMultiplier = data.jackpotGame.jackpotMultiplier;
    }

    if (data.bonusGameData) {
      this._bonusGameTotalWin = data.bonusGameData.totalWin || 0;
      if (this.bonusGameEnabled && data.bonusGameData.totalWinPerSpin) window.OPWrapperService.ControllerStatistic.win = data.bonusGameData.totalWinPerSpin;
      this._respins = data.bonusGameData.respins;
      this._bonusGameTriggered = data.bonusGameData.bonusGameTriggered;
      this._coinSymbolsValues = data.bonusGameData.coinSymbolsValues
      this._coinSymbolsValuesList = data.bonusGameData.coinSymbolsValuesList
      this._bonusGameAnimatonsPaths = data.bonusGameData.bonusGameAnimatonsPaths
      this._bonusGameAvalibleReels = data.bonusGameData.bonusGameAvalibleReels || this._bonusGameAvalibleReels;
    }
    this._isBonusGameMode = data.bonusGameData && data.bonusGameData.isBonusGame;

    this._progresesToJackpot = data.progresesToJackpot || this._progresesToJackpot;

    this._jackpotCoeffs = data.jackpotCoeffs || this._jackpotCoeffs;
    this._isJackpotGame = data.jackpotGame && data.jackpotGame.isJackpotGame;

    GlobalDispatcher.dispatch('model:getServerData', data.action);
  }

  get paytableCurrentInfo() {
    this._paytableCurrentInfo = {}
    for (const index in this._paytableInfo) {
      this._paytableCurrentInfo[index] = this._paytableInfo[index].map(coef => {
        return `×${coef}`;
      })
    }
    this._paytableCurrentInfo['wildField'] = this._paytableInfo[1][2] * this.lines

    return this._paytableCurrentInfo
  }
  get isJackpotGame() {
    return this._isJackpotGame;
  }
  get jackpotField() {
    return this._jackpotField;
  }
  get jackpotResults() {
    return this._jackpotResults;
  }
  get winJackpotType() {
    return this._winJackpotType;
  }
  get jackpotWinAmount() {
    return this._jackpotWinAmount;
  }
  get jackpotList() {
    return this._jackpotCoeffs.map(coef => this.bet * coef);
  }
  get jackpotMaxWin() {
    const maxBet = this._betPossible[this._betPossible.length - 1];
    return this._jackpotCoeffs[this._jackpotCoeffs.length - 1] * maxBet;
  }
  get jackpotMultiplier() {
    return this._jackpotMultiplier;
  }
  get respins() {
    return this._respins;
  }
  set respins(value) {
    this._respins = value;
  }
  get bonusGameTotalWin() {
    return this._bonusGameTotalWin;
  }
  set bonusGameTotalWin(value) {
    this._bonusGameTotalWin = value;
  }
  get isBonusGameMode() {
    return this._isBonusGameMode;
  }
  get bonusGameEnabled() {
    return this._bonusGameEnabled;
  }
  set bonusGameEnabled(value) {
    this._bonusGameEnabled = value;
    this._isSpinWithoutBalanceCheck = value;
  }
  get bonusGameTriggered() {
    return this._bonusGameTriggered;
  }
  get progressToJackpot() {
    return this._progresesToJackpot[this._betIndex].progress;
  }
  get totalWin() {
    let win = 0
    if (this._winnings) {
      win += this.winnings.totalWin;
      if (this.isJackpotGame) {
        win -= this.jackpotWinAmount
      }
    }
    return win;
  }
  get coinSymbolsValues() {
    return this._coinSymbolsValues;
  }
  get coinSymbolsValuesList() {
    return this._coinSymbolsValuesList;
  }
  get bonusGameAnimatonsPaths() {
    return this._bonusGameAnimatonsPaths;
  }
  get bonusGameAvalibleReels() {
    return this._bonusGameAvalibleReels;
  }
  get winRankCoef () {
    return this._winRankCoef;
  }
  get freespins() {
    return this.respins;
  }
  get freespinsTotalWin() {
    return this.bonusGameTotalWin;
  }
  get freespinsEnabled() {
    return this.bonusGameEnabled;
  }
}
