import { goldFontStyle } from '../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import { gsap } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import {eSounds} from "../../enums/eSoundTypes";

export default class ControllerBonusGameStart extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init();
  }
  init() {
    super.init();
    this.youWinText = this.getChildByName('bonus_game_popup_start_you_win');
    this.setTextStile();
  }
  setTextStile() {
    this.youWinText.style = { ...this.youWinText.style, ...goldFontStyle };
  }

  onShow() {
    super.onShow();
    setTimeout(() => {
        this.hide();
      }
      ,3000)
    SoundManager.play(eSounds.EST_START_BOUNS_GAME.soundName, eSounds.EST_START_BOUNS_GAME.volume,false, eSounds.EST_START_BOUNS_GAME.group);
  }

  onHide() {
    super.onHide();
    GlobalDispatcher.dispatch('freespinsStart: onComplete');
  }
}
