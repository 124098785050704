import EntryPoint from 'Engine/EntryPoint';
import { gsap } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import StatesManager from '../libs/game-engine/src/base/states/StatesManager';
import eStateTypes from '../enums/eStateTypes';

export default class BaseGameUiContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
  }

  init() {
    this.bonusGameTotalWinPanel = this.getChildByName('totalWinPanel');
    this.bonusGameTotalWinPanel.setValue(
      EntryPoint.GameModel.bonusGameTotalWin
    );
    this.bonusGameTotalWinPanel.alpha = EntryPoint.GameModel.isBonusGameMode ? 1 : 0;
    this.bonusGameTotalWinPanel.visible = EntryPoint.GameModel.isBonusGameMode;
  }

  addListeners() {
    GlobalDispatcher.add(
      'stateManager.stateChanged',
      this.onStateChanged,
      this
    );
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data, priorityFrameIndex) {
    const frameIndex = priorityFrameIndex || (data.isLandscape ? 2 : 1);
    if (frameIndex !== this.currentFrameIndex) {
      this.goToFrame(frameIndex);
      this.init();
      this.onStateChanged({
        params: StatesManager.currentState.stateData.name,
      });
    }

    GlobalDispatcher.dispatch(
      'uiContainer:winContainerData',
      this.bonusGameTotalWinPanel
    );
  }

  shoBonusGamePanels() {
    this.bonusGameTotalWinPanel.visible = true;
    this.bonusGameTotalWinPanel.setValue(
      EntryPoint.GameModel.bonusGameTotalWin
    );
    gsap.to(this.bonusGameTotalWinPanel, { alpha: 1, duration: 0.6 });
  }

  hideBonusGamePanels() {
    gsap.to(this.bonusGameTotalWinPanel, {
      alpha: 0,
      duration: 0.6,
      onComplete: () => {
        this.bonusGameTotalWinPanel.visible = false;
      },
    });
  }

  onStateChanged(event) {
    switch (event.params) {
      case eStateTypes.EBST_IDLE:
        if (EntryPoint.GameModel.isBonusGameMode) {
          this.bonusGameTotalWinPanel.setValue(
            EntryPoint.GameModel.bonusGameTotalWin
          );
        }
        break;
      case eStateTypes.EST_BONUS_GAME_IN:
        this.shoBonusGamePanels();
        break;
      case eStateTypes.EST_BONUS_GAME_OUT:
        this.hideBonusGamePanels();
        break;
    }
  }
}
