import FlashLib from 'flashlib_onlyplay';
import eStonesType from './eStonesType';

export default class ControllerStones extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.stones = {};
    for (const key in eStonesType) {
      const stone = this.getChildByName(eStonesType[key]);
      stone.visible = false;
      this.stones[eStonesType[key]] = stone;
    }
  }
  setStone(type) {
    for (const key in this.stones) {
      this.stones[key].visible = key === type;
    }
  }
}
