import eStateTypes from './eBaseStateTypes';

export default {
  EBET_ERROR_MANAGER__SHOW_ERROR: 'errorManager:showError', //Engine
  EBET_SERVER_MANAGER__UPDATE_BALANCE: 'dataProvider:updateBalance',
  EBET_GAME__STARTED: 'game:gameStarted', //Engine
  EBET_PRELOADER_CONTROLLER__UPDATE_PROGRESS: 'preloaderController:progress', //Engine
  EBET_BET_CHANGED: 'model:betChanged',
  EBET_AVAILABLE_BETS_CHANGED: 'model:betPossibleChanged',
  EBET_UI_CHANGE_BET: 'ui:changeBet',
  EBET_DATA_UPDATED: 'model:getServerData',

  EBET_SPIN_CLICK: 'ui:spinClick',
  EBET_SETTINGS__CLICK: 'ui:settingsClick',
  EBET_SET_MAX_BET: 'ui:setMaxBet',
  EBET_SET_MIN_BET: 'ui:setMinBet',
  EBET_PAYTABLE__CLICK: 'ui:paytableClick',
  EBET_PAYTABLE__CHANGE_PAGE: 'paytable:changePage',
  EBET_PAYTABLE__BACK_CLICK: 'paytable:backClick',
  EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE: 'popupWin:bigWinsAnimationVisibleChange',

  EBET_PURCHASE_BTN_CLICK: 'ui:openPurchaseClick',
  EBET_PURCHASE__CLOSE: 'ui:closePurchase',

  EBET_BUY_BONUS: 'bonusGame:buy',
  EBET_PURCHASE_CONFIRM: 'bonusGame:confirm',

  EBET_SETTINGS__SOUND_MUTE: 'gameSettings:soundMute', //Engine
  EBET_SETTINGS__MUSIC_MUTE: 'gameSettings:musicMute', //Engine
  EBET_SETTINGS__WINNING_MUTE: 'gameSettings:winningMute', //Engine
  EBET_AUTOPLAY_SETTINGS__ACTIVE_CHANGED: 'autoPlaySettings:activeChanged', //Engine

  EBET_REELS_STOPPED: 'reelsContainer:reelsStopped',

  EBET_SPACIAL_SYMBOL_HIT_THE_BAR: 'specilalSymbol: hitTheBar',

  EBET_STATE_MANAGER__STATE_CHANGED: 'stateManager.stateChanged', //Engine
  EBET_WINNINGS_CONTROLLER__ALL_WINNINGS_COMPLETE: 'winningsController:allWinningsComplete',
  EBET_MODEL__LINES_CHANGED: 'model:linesChanged', //Engine

  EBET_FREESPINS_START__SHOWN_UP: 'freespinsStart: shownUp',
  EBET_FREESPINS_END__SHOWN_UP: 'freespinsEnd: shownUp',
  EBET_FREESPINS_END__ON_COMPLETE: 'freespinsEnd: onComplete',
  EBET_FREESPINS_START__ON_COMPLETE: 'freespinsStart: onComplete', //Engine
  EBET_WINNINGS_CONTROLLER__SLOW_WIN: 'winningsController:slowWin', //Engine
  EBET_START_SCREEN__SHOW: 'startScreen:show',
  EBET_START_SCREEN__HIDE: 'startScreen:hide',
  EBET_BIG_WIN__SKIP: 'bigWinContainer:stopState', //Engine
  EBET_BIG_WIN__SKIP_COMPLETED: 'bigWinContainer:nextState', //Engine

  EBET_INIT_STATE__END: `stateEnd:${eStateTypes.EBST_INIT}`, //Engine
  EBET_IDLE_STATE__START: `stateStart:${eStateTypes.EBST_IDLE}`,
  EBET_START_SCREEN__STATE__START: `stateStart:${eStateTypes.EBST_START_SCREEN}`,
  EBET_ROLLING_STATE__START: `stateStart:${eStateTypes.EBST_REQUEST_ROLLING}`,
  EBET_SHOW_WIN_STATE__START: `stateStart:${eStateTypes.EBST_SHOW_WIN}`,
  EBET_STOP_WIN_STATE__START: `stateStart:${eStateTypes.EBST_STOP_WIN}`,
  EBET_STOP_WIN_STATE__END: `stateEnd:${eStateTypes.EBST_STOP_WIN}`,
  EBET_FREESPIN_IN_STATE__START: `stateStart:${eStateTypes.EBST_FREE_SPINS_IN}`,
  EBET_FREESPIN_IN_STATE__END: `stateEnd:${eStateTypes.EBST_FREE_SPINS_IN}`,
  EBET_FREESPIN_OUT_STATE__START: `stateStart:${eStateTypes.EBST_FREE_SPINS_OUT}`,
  EBET_FREESPIN_OUT_STATE__END: `stateEnd:${eStateTypes.EBST_FREE_SPINS_OUT}`,

  EBET_SHOW_USER_INTERFACE: 'userInterface:show',
  EBET_HIDE_USER_INTERFACE: 'userInterface:hide',
  EBET_ANTISIPATOR__START: 'antisipators: start',
  EBET_ANTISIPATOR__STOP: 'antisipators: stop',
}
