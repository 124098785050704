import FlashLib from 'flashlib_onlyplay';
import { goldFontStyle, goldFontWithoutStokesStyle } from '../fontStyles';

export default class RespinsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }
  init() {
    this.lableField = this.getChildByName('bonus_game_respins');
    this.amountField = this.getChildByName('amount');
    this.setTextStyles();
  }
  setTextStyles() {
    this.lableField.style = { ...this.lableField.style, ...goldFontStyle };
    this.amountField.style = {
      ...this.amountField.style,
      ...goldFontWithoutStokesStyle,
    };
  }
  setValue(value) {
    this.amountField.text = `${value}`;
  }
}
