import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from 'Engine/enums/eBaseEventTypes';

export default class StopWinState extends BaseState {
  constructor(stateData) {
    super(stateData);

    this.timeout = null;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('bigWinContainer:nextState', this.stopNow, this);
  }

  stopNow() {
    if (this.active) {
      this.complete();
    }
  }

  stop() {
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
    super.stop();
  }

  complete() {
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
    super.complete();
  }
}
