import FlashLib from 'flashlib_onlyplay';
import Particle from 'Engine/particles/particle';
const config = {
  x0min: 0, // px
  x0max: 0, // px
  y0min: 0, // px
  y0max: 0, // px

  gravityX: 0,
  mass: 0.05, // ~kg
  drag: 1 / 50,
  lifetimeMin: 1000, // milliseconds
  lifetimeMax: 1000, // milliseconds
  x0: 0,
  y0: 0,
  count: 200,
  scaleStart: 1,
  scaleEnd: 0,
  angle0From: 0,
  angle0To: 2 * Math.PI,
  angleVelocityMin: 0.005,
  angleVelocityMax: 0.005,
  v0min: 0.025, // px / milliseconds
  v0max: 0.05, // px / milliseconds
  thickness: 12,
  gravityY: 0,
  box: {
    x: 0,
    y: 0,
  },
  leftBound: false,
  topBound: false,
  rightBound: false,
  bottomBound: false,
};

export default class ControllerStone extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.config = { ...config };
    this.particles = [];
    this.particleContainer = new PIXI.Container();
    this.addChildAt(this.particleContainer, 0);
    this.particleInfo = [
      { texture: PIXI.Texture.from('stoneParticle0') },
      { texture: PIXI.Texture.from('stoneParticle1') },
      { texture: PIXI.Texture.from('stoneParticle2') },
    ];
    this.imageStone = this.children[1];
    this.onComplete = () => {};
  }
  to({ bezierConfig, width, height }, onComplete) {
    this.onComplete = onComplete;
    this.stoneConfig = { width, height };
    this.config.bezier = bezierConfig;
    this.timeStartBezier = Date.now();
    this.timeUpdate = this.timeStartBezier;
    this.duration = this.config.bezier.duration;
    this.frameDuration = this.duration / 1000;

    requestAnimationFrame(this.updateBezier.bind(this));
  }

  getBezierPosition(progress, points) {
    return (
      (1 - progress) * (1 - progress) * points[0] +
      2 * (1 - progress) * progress * points[1] +
      progress * progress * points[2]
    );
  }

  updateBezier() {
    const nowDate = Date.now();
    const differenceTimeFromStart = nowDate - this.timeStartBezier;
    const differenceTime = nowDate - this.timeUpdate;
    if (this.duration <= differenceTimeFromStart) {
      this.drawFunction(1);
      this.pause();
      this.onComplete();
      setTimeout(() => {
        this.reset();
      }, this.duration + 50);
      return;
    } else if (differenceTime >= this.frameDuration) {
      this.timeUpdate = nowDate;
      const progress = differenceTimeFromStart / this.duration;
      this.drawFunction(progress);
      requestAnimationFrame(this.updateBezier.bind(this));
    } else {
      requestAnimationFrame(this.updateBezier.bind(this));
    }
  }

  drawFunction(progress) {
    const x0min =
      this.getBezierPosition(progress, [
        this.config.bezier.p0.x,
        this.config.bezier.p1.x,
        this.config.bezier.p2.x,
      ]) +
      this.imageStone.width / 2;
    const x0max = x0min;
    const y0min =
      this.getBezierPosition(progress, [
        this.config.bezier.p0.y,
        this.config.bezier.p1.y,
        this.config.bezier.p2.y,
      ]) +
      this.imageStone.height / 2;
    const y0max = y0min;
    const stoneX = this.getBezierPosition(progress, [
      this.config.bezier.p0.x,
      this.config.bezier.p1.x,
      this.config.bezier.p2.x,
    ]);
    const stoneY = this.getBezierPosition(progress, [
      this.config.bezier.p0.y,
      this.config.bezier.p1.y,
      this.config.bezier.p2.y,
    ]);
    const stoneHeight =
      this.displayData.height +
      (this.stoneConfig.height - this.displayData.height) * progress;
    const stoneWidth =
      this.displayData.width +
      (this.stoneConfig.width - this.displayData.width) * progress;

    for (let i = 0; i < 6; i++) {
      const particle = new Particle(
        this.particleContainer,
        {
          ...this.config,
          x0min,
          x0max,
          y0min,
          y0max,
          lifetimeMin: this.duration,
          lifetimeMax: this.duration + 50,
          blendMode: PIXI.BLEND_MODES.ADD,
        },
        this.timeStartBezier,
        this.particleInfo[i % 3]
      );
      this.particles.push(particle);
    }
    this.imageStone.x = stoneX;
    this.imageStone.y = stoneY;
    this.imageStone.width = stoneWidth;
    this.imageStone.height = stoneHeight;
  }
  pause() {
    for (let particle of this.particles) {
      particle.pause();
    }
    this.particles = [];
    this.isStarted = false;
  }

  reset() {
    this.imageStone.x = this.imageStone.displayData.x;
    this.imageStone.y = this.imageStone.displayData.y;
    this.imageStone.width = this.imageStone.displayData.width;
    this.imageStone.height = this.imageStone.displayData.height;
  }
}
