import FlashLib from 'flashlib_onlyplay';
import ControllerFlyCoinsValue from './ControllerFlyCoinsValue';
import ControllerBonusJokerAnimations from './ControllerBonusJokerAnimations';
import ControllerBringToFrontSymbols from './ControllerBringToFrontSymbols';

export default class ControllerSlot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    this.addListeners();
  }

  init() {
    this.landscapeLogo = this.getChildByName('logo');
    this.capitals = this.getChildByName('capitals');
    this.reelsContainer = this.getChildByName('slotContainer').getChildByName('reelsContainer');

    this.ControllerReels = this.reelsContainer.ControllerReels;

    this.ControllerBringToFrontSymbols = new ControllerBringToFrontSymbols();
    this.ControllerBonusJokerAnimations = new ControllerBonusJokerAnimations(this.ControllerReels);
    this.ControllerFlyCoinsValue = new ControllerFlyCoinsValue(this.ControllerReels);
    this.addChild(
      this.ControllerBringToFrontSymbols,
      this.ControllerBonusJokerAnimations,
      this.ControllerFlyCoinsValue
    );
    this.ControllerBonusJokerAnimations.init();
  }

  onGameResized(data) {
    const frame = (data.isMobile && data.isPortrait) ? 1 : 2;
    this.landscapeLogo.goToFrame(frame);
    this.capitals.goToFrame(frame);
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }
}
