import FlashLib from 'flashlib_onlyplay';
import { wonJackpotGameFontStyle } from '../../fontStyles';
import { gsap } from 'gsap';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eServerClientStone from './eServerClientStone';
import SoundManager from 'Engine/soundManager/SoundManager';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { eSounds } from '../../enums/eSoundTypes';

export default class PopupWonJackpot extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.alpha = 0;
    this.visible = false;
    this.jackpotText = this.getChildByName('popup_won_jackpot_jackpot');

    this.simplePanel = this.getChildByName('simplePanel');
    this.simpleJackpotAmount = this.simplePanel.getChildByName('jackpotAmount');

    this.bigPanel = this.getChildByName('bigPanel');
    this.bigPanelSimpleJackpotAmount =
      this.bigPanel.getChildByName('jackpotAmount');
    this.jackpotMultiplier = this.bigPanel.getChildByName('jackpotMultiplier');
    this.bigJackpotAmount = this.bigPanel.getChildByName('bigJackpotAmount');

    // this.bgAnimation = this.getChildByName('bgAnimation');
    this.jackpotTweenAmount = 0;
    this.setTextsSyle();
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('panel: maxIcons', this.show, this);
  }

  setTextsSyle() {
    this.jackpotText.style = {
      ...this.jackpotText.style,
      ...wonJackpotGameFontStyle,
    };
    this.simpleJackpotAmount.style = {
      ...this.simpleJackpotAmount.style,
      ...wonJackpotGameFontStyle,
    };
    this.bigPanelSimpleJackpotAmount.style = {
      ...this.bigPanelSimpleJackpotAmount.style,
      ...wonJackpotGameFontStyle,
    };
    this.jackpotMultiplier.style = {
      ...this.jackpotMultiplier.style,
      ...wonJackpotGameFontStyle,
    };
    this.bigJackpotAmount.style = {
      ...this.bigJackpotAmount.style,
      ...wonJackpotGameFontStyle,
    };
  }

  show() {
    this.visible = true;
    // this.bgAnimation.start();
    gsap.to(this, { alpha: 1, duration: 0.6 });

    this.showJackpotPanel();

    this.jackpotEndValue = EntryPoint.GameModel.jackpotWinAmount;
    this.bigPanelSimpleJackpotValue =
      this.jackpotEndValue / EntryPoint.GameModel.jackpotMultiplier;

    this.jackpotText.text =
      window.OPWrapperService.localizations.getLocalizedText(
        `popup_won_jackpot_${
          eServerClientStone[EntryPoint.GameModel.winJackpotType]
        }`
      );

    this.decimals = Math.min(
      this.jackpotEndValue.getPrecision(),
      EntryPoint.GameModel.currencyInfo.decimals
    );
    this.bigPanelSimpleJackpotDecimals = Math.min(
      this.bigPanelSimpleJackpotValue.getPrecision(),
      EntryPoint.GameModel.currencyInfo.decimals
    );
    const arrForDecimals = MoneyFormat.formatCurrency(
      this.jackpotEndValue,
      this.decimals
    ).split('.');
    const arrForSimplePanelDecimals = MoneyFormat.formatCurrency(
      this.bigPanelSimpleJackpotValue,
      this.bigPanelSimpleJackpotDecimals
    ).split('.');
    this.decimalsForAnimation = arrForDecimals[1]
      ? arrForDecimals[1].length
      : 0;
    this.simplePanelDecimalsForAnimation = arrForSimplePanelDecimals[1]
      ? arrForSimplePanelDecimals[1].length
      : 0;

    gsap.fromTo(
      this,
      { jackpotTweenAmount: 0 },
      {
        jackpotTweenAmount: this.jackpotEndValue,
        duration: 3,
        onComplete: () => {
          this.jackpotTweenAmount = 0;
          this.simpleJackpotAmount.text = `${MoneyFormat.formatCurrency(
            this.jackpotEndValue,
            this.decimals
          )} ${EntryPoint.GameModel.currencyInfo.currency}`;
          this.bigPanelSimpleJackpotAmount.text = `${MoneyFormat.formatCurrency(
            this.bigPanelSimpleJackpotValue,
            this.bigPanelSimpleJackpotDecimals
          )} ${EntryPoint.GameModel.currencyInfo.currency}`;
          this.bigJackpotAmount.text = `${MoneyFormat.formatCurrency(
            this.jackpotEndValue,
            this.decimals
          )} ${EntryPoint.GameModel.currencyInfo.currency}`;

          setTimeout(this.hide.bind(this), 2000);
        },
      }
    );
    SoundManager.play(
      eSounds.EST_JACKPOT_GAME_JACKPOT.soundName,
      eSounds.EST_JACKPOT_GAME_JACKPOT.volume,
      false,
      eSounds.EST_JACKPOT_GAME_JACKPOT.group
    );
  }

  set jackpotTweenAmount(value) {
    this._jackpotTweenAmount = value;
    this.simpleJackpotAmount.text = `${this._jackpotTweenAmount.toFixed(
      this.decimalsForAnimation
    )} ${EntryPoint.GameModel.currencyInfo.currency}`;
    this.bigPanelSimpleJackpotAmount.text = `${(
      this._jackpotTweenAmount / EntryPoint.GameModel.jackpotMultiplier
    ).toFixed(this.simplePanelDecimalsForAnimation)} ${
      EntryPoint.GameModel.currencyInfo.currency
    }`;
    this.bigJackpotAmount.text = `${this._jackpotTweenAmount.toFixed(
      this.decimalsForAnimation
    )} ${EntryPoint.GameModel.currencyInfo.currency}`;
  }

  get jackpotTweenAmount() {
    return this._jackpotTweenAmount;
  }
  hide() {
    StatesManager.goToNextState();
    gsap.to(this, 0.6, {
      alpha: 0,
      onComplete: () => {
        // this.bgAnimation.stop();
        this.visible = false;
      },
    });
  }
  showJackpotPanel() {
    if (EntryPoint.GameModel.jackpotMultiplier > 1) {
      this.bigPanel.visible = true;
      this.simplePanel.visible = false;
      this.jackpotMultiplier.text = `×${EntryPoint.GameModel.jackpotMultiplier}`;
    } else {
      this.bigPanel.visible = false;
      this.simplePanel.visible = true;
    }
  }
}
