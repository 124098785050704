import { shadowText } from '../fontStyles';
import BaseGameButton from './BaseGameButton';

export default class MaxBetButton extends BaseGameButton {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
  }

  init() {
    this.titleText = this.getChildByName('ui_max_bet_label');

    this.titleText.style = { ...this.titleText.style, ...shadowText };
  }

  setSate(frameIndex) {
    super.setSate(frameIndex);
    if (this.titleText) {
      this.titleText.style.fill = frameIndex === 4 ? '#C6C6C6' : '#FFF000';
    }
  }
}
