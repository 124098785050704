import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { goldFontStyle } from '../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import LocalizeTextField from 'Engine/base/localization/LocalizeTextField';

export default class PaytableContent extends FlashLib.MovieClip {
  constructor(data, displayData) {
    const symbolName = data.timeline.name;
    const PaytableJackpotContentData = FlashLib.getItemDataFromLibrary(
      `jackpotMovieClips/paytable/${symbolName}`,
      'jackpotGameFlashLib'
    );
    data.timeline.frameCount = PaytableJackpotContentData.timeline.frameCount;
    data.timeline.layers[0].frameCount =
      PaytableJackpotContentData.timeline.layers[0].frameCount;
    data.timeline.layers[0].frames[5] =
      PaytableJackpotContentData.timeline.layers[0].frames[5];
    super(data, displayData);

    this.currentFrame = 1;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add('paytable:backClick', this.reset, this);
    GlobalDispatcher.add('paytable:changePage', this.changeFrame, this);
  }

  setMultipliersStyle() {
    switch (this.currentFrame) {
      case 1:
        for (let i = 1; i <= 7; i++) {
          const coefs = this.children[0].getChildByName(
            `paytable_p1_coefs${i}`
          );
          const counts = this.children[0].getChildByName(
            `paytable_p1_count${i}`
          );
          if (counts) {
            counts.style = { ...counts.style, ...goldFontStyle };
          }
          if (coefs) {
            coefs.style = { ...coefs.style, ...goldFontStyle };
          }
        }
        break;
      case 2:
        const coefs = this.children[0].getChildByName(`paytable_p2_coefs1`);
        const counts = this.children[0].getChildByName(`paytable_p2_count1`);
        if (counts) {
          counts.style = { ...counts.style, ...goldFontStyle };
        }
        if (coefs) {
          coefs.style = { ...coefs.style, ...goldFontStyle };
        }
        break;
      case 4:
        const desc4 = this.children[0].getChildByName('paytable_3_1');
        desc4.fitSize(true, true);
        break;

      case 5:
        const desc5 = this.children[0].getChildByName('paytable_4_1');
        desc5.fitSize(true, true);
        break;
      case 6:
        this.miniPanelAmount = this.children[0]
          .getChildByName('panel_mini')
          .getChildByName('totalAmount');
        this.minorPanelAmount = this.children[0]
          .getChildByName('panel_minor')
          .getChildByName('totalAmount');
        this.majorPanelAmount = this.children[0]
          .getChildByName('panel_major')
          .getChildByName('totalAmount');
        this.megaPanelAmount = this.children[0]
          .getChildByName('panel_mega')
          .getChildByName('totalAmount');
        this.grandPanelAmount = this.children[0]
          .getChildByName('panel_grand')
          .getChildByName('totalAmount');
        [
          this.miniPanelAmount,
          this.minorPanelAmount,
          this.majorPanelAmount,
          this.megaPanelAmount,
          this.grandPanelAmount,
        ].forEach((panel, index) => {
          panel.text = MoneyFormat.format(
            EntryPoint.GameModel.jackpotList[index],
            true
          );
          panel.correctPosition = function () {
            LocalizeTextField.prototype.correctPosition.call(
              this,
              'center',
              'center'
            );
          };
          panel.fitSize(true);
        });
        break;
    }
  }

  setMultipliers() {
    switch (this.currentFrame) {
      case 1:
        for (
          let i = 2;
          i < Object.keys(EntryPoint.GameModel.paytableInfo).length;
          i++
        ) {
          let element = this.children[0].getChildByName(
            `paytable_p1_coefs${i - 1}`
          );
          if (element) {
            let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[i]];
            amounts = amounts.slice(2, amounts.length);
            // amounts = amounts.map(amount => MoneyFormat.formatCurrency(amount, EntryPoint.GameModel.currencyInfo.decimals))
            amounts.reverse();
            element.text = amounts.join('\n\n');
          }
        }
        // const wildFieldCoef = this.children[0].getChildByName('paytable_wild_coef');
        // wildFieldCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildField'], EntryPoint.GameModel.currencyInfo.decimals);
        // const wildFieldPlusChiliMaxCoef = this.children[0].getChildByName('paytable_chili_coef');
        // wildFieldPlusChiliMaxCoef.text = MoneyFormat.formatCurrency(EntryPoint.GameModel.paytableCurrentInfo['wildFieldPlusChili'], EntryPoint.GameModel.currencyInfo.decimals);
        break;
      case 2:
        let element = this.children[0].getChildByName(`paytable_p2_coefs1`);
        if (element) {
          let amounts = [...EntryPoint.GameModel.paytableCurrentInfo[1]];
          amounts = amounts.slice(2, amounts.length);
          // amounts = amounts.map(amount => MoneyFormat.formatCurrency(amount, EntryPoint.GameModel.currencyInfo.decimals))
          amounts.reverse();
          element.text = amounts.join('\n\n');
        }
        break;
    }
  }

  setTextsStyle() {
    for (let i = 0; i < 8; i++) {
      const text = this.children[0].getChildByName(
        `paytable_${this.currentFrame - 1}_${i}`
      );
      if (text) {
        text.style = { ...text.style, ...goldFontStyle };
      }
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params || 1;
    this.goToFrame(this.currentFrame);
    this.setMultipliersStyle();
    this.setMultipliers();
    this.setTextsStyle();
  }

  reset() {
    this.currentFrame = 1;
    this.goToFrame(this.currentFrame);
    this.setMultipliersStyle();
    this.setMultipliers();
  }

  destroy() {
    GlobalDispatcher.remove('paytable:backClick', this.reset, this);
    GlobalDispatcher.remove('paytable:changePage', this.changeFrame, this);
    super.destroy();
  }

  goToFrame(index) {
    this.libName = index === 6 ? 'jackpotGameFlashLib' : 'GameFlashLib';
    super.goToFrame(index);
  }
}
