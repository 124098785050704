import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class WinAmountAnimated {
  constructor(element, style) {

    this.label = new PIXI.BitmapText('0', { ...element.style,  ...style, fontName: 'BMFont' });
    this.label.anchor.set(0.5);
    // this.label.x = element.displayData.width / 2;
    this.label.y = element.displayData.height / 2;
    this.label.visible = false;
    element.removeChildren();
    element.addChild(this.label);

    this.onStop = () => {};
    this.onComplete = () => {};
  }

  animate(startValue = 0, endValue, decimals = 2, duration, timeOffset) {
    this.needStop = false;
    this.label.text = MoneyFormat.formatCurrency(startValue, this.decimals);

    this.timeout && clearTimeout(this.timeout);

    this.label.visible = true;
    this.startValue = startValue;
    this.endValue = endValue;
    this.decimals = decimals;
    const arrForDecimals = MoneyFormat.formatCurrency(endValue, this.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;
    this.duration = duration;
    this.timeOffset = timeOffset;
    this.startTime = Date.now();
    requestAnimationFrame(this.animateValueChanged.bind(this));
  }

  stop(){
    this.needStop = true;
  }

  _onStop() {
    this.onStop();
    this.timeout = setTimeout(() => {
      this.label.text = '';
      this.label.visible = false;
      this.onComplete();
      this.complete();
    }, this.timeOffset)
  };

  complete() {
    this.timeout && clearTimeout(this.timeout);
  }

  animateValueChanged() {
    const time = Date.now() - this.startTime;

    if (time >= this.duration || this.needStop) {
      this.label.text = MoneyFormat.formatCurrency(this.endValue, this.decimals, false);
      return this._onStop();
    }

    const progress = time / this.duration;
    const amount = (this.endValue - this.startValue) * progress + this.startValue;
    this.label.text = amount.toFixed(this.decimals);

    requestAnimationFrame(this.animateValueChanged.bind(this));
  };
}
