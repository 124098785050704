import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class bonusGameInState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('freespinsStart: onComplete', this.onCounterComplete, this)
  }

  onCounterComplete() {
      if(this.active) {
        this.complete();
      }
  }
  start() {
    super.start();
    OPWrapperService.infoPanel.setGameState(eGameStateTypes.EGST_BONUS_GAME);
    EntryPoint.GameModel.bonusGameEnabled = true;
    EntryPoint.AutoPlaySettings.active = false;
  }
}
