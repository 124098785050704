import eBaseStateTypes from '../libs/game-engine/src/enums/eBaseStateTypes';

export default {
  EST_REQUEST_BONUS: 'requestBonusState',
  EST_SHOW_WON_JACKPOT: 'showWonJackpotGameState',
  EST_JACKPOT_GAME_IN : 'jackpotGameInState',
  EST_JACKPOT_GAME_IDLE: 'jackpotGameIdleState',
  EST_JACKPOT_GAME_OUT: 'jackpotGameOutState',
  EST_BONUS_GAME_OUT: 'bonusGameOutState',
  EST_BONUS_GAME_IN: 'bonusGameInState',
  EST_SHOW_WIN_JACKPOT_GAME_SYMBOL : 'showWinJackpotGameSymbolState',
  EST_ANIMATION_JACKPOTTER : 'animationJackpotterState',
  EST_FLY_VALUE : 'flyValueState',
  EST_SHOW_LINES_WIN : 'showLinesWinState',
  EST_SHOW_WINNINGS: 'showWinningsState',
  ...eBaseStateTypes,
};
