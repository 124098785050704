import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import StatesManager from 'Engine/base/states/StatesManager';
import EntryPoint from 'Engine/EntryPoint';

export default class PaytableContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    this.addListeners();
    this.hide();
    this.eventMode = 'static';
  }

  init() {
    this.background = this.getChildByName('background');
    this.content = this.getChildByName('content');

    this.controls = this.getChildByName('controls');
    this.pagesCount = this.content.layersData[0].frameCount;

    let range = [];
    for (let i = 1; i <= this.pagesCount; i++) {
      range.push(i);
    }

    this.controls.setRange(range);
    this.content.changeFrame({ params: this.controls.currentPage });
  }

  addListeners() {
    GlobalDispatcher.add('ui:paytableClick', this.show.bind(this));
    GlobalDispatcher.add('paytable:backClick', this.hide.bind(this));
    GlobalDispatcher.add('ui:spinClick', this.hide.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize(data) {
    if (data.isLandscape) {
      this.background.goToFrame(2);
      this.goToFrame(1);
      this.init();
      this.content.changeFrame({ params: this.controls.currentPage });
      this.controls.x = this.controls.displayData.x;
      this.controls.y = this.controls.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
      this.goToFrame(2);
      this.init();
      this.content.changeFrame({ params: this.controls.currentPage });
      this.controls.x = 903;
      this.controls.y = 1065;
    }
  }

  show() {
    this.visible = true;
    this.content.setMultipliers();
    this.content.setTextsStyle();
  }

  hide() {
    this.visible = false;
  }
}
