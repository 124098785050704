import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class showWonJackpotGameState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    start() {
        super.start();
        OPWrapperService.infoPanel.setGameState(eGameStateTypes.EGST_JACKPOT_GAME);
    }

}
