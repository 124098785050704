import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default class AnimationJackpotterState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('ControllerSlot: jackpotterAnimationComplete', this.openAccessToNextState, this)
    }

    openAccessToNextState() {
        if(this.active) {
            this.stop();
        }
    }

}
