import ePartnerConfigProperties from './ePartnerConfigProperties';

export default class PartnerConfig {
  constructor() {
    this.lang = 'en';
    this.jackpotEnabled = true;
    this.iFrameHomeButton = false;
    this.homeButtonHistoryBackDisable = false;
    this.cancelEnabled = true;
    this.showLimitsOnStart = false;
    this.showRulesOnStart = false;
    this.disableAutoplay = false;
    this.skipBlocked = false;
    this.roundDuration = null;
    this.minWinRankCoef = null;
    this.defaultBetIndex = 0;
    this.showOperatorBetsIds = false;
    this.disableBuyBonus = false;
    this.disableSpinBySpace = false;
    this.enableRealityCheck = false;
    this.lightMode = false;
    this.isMaxBetsCountOne = false;
    this.certificationName = 'default';
    this.session = {
      checkStatus: false,
      checkStatusInterval: 0,
    };
    this.gameConfig = {};
    this.messageEvents = {};
    this.infoPanel = {};
  }

  updateData(data, lang) {
    if (lang) this.lang = lang;

    if (!data) return;

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_JACKPOT_ENABLED))
      this.jackpotEnabled = data[ePartnerConfigProperties.EPCP_JACKPOT_ENABLED];

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_IFRAME_HOME_BUTTON)) {
      this.iFrameHomeButton = data[ePartnerConfigProperties.EPCP_IFRAME_HOME_BUTTON];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_AVAILABLE_BETS)) {
      this.available_bets = data[ePartnerConfigProperties.EPCP_AVAILABLE_BETS];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_MAX_WIN_LIMIT)) {
      this.maxWin = data[ePartnerConfigProperties.EPCP_MAX_WIN_LIMIT];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_MAX_WIN_ON_START)) {
      this.showLimitsOnStart = data[ePartnerConfigProperties.EPCP_SHOW_MAX_WIN_ON_START];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_CURRENCY)) {
      this.currency = data[ePartnerConfigProperties.EPCP_CURRENCY];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_CURRENCY)
      && data.hasOwnProperty(ePartnerConfigProperties.EPCP_CURRENCIES_DATA)
      && data[ePartnerConfigProperties.EPCP_CURRENCIES_DATA].hasOwnProperty(data[ePartnerConfigProperties.EPCP_CURRENCY])
      && data[ePartnerConfigProperties.EPCP_CURRENCIES_DATA][data[ePartnerConfigProperties.EPCP_CURRENCY]].hasOwnProperty(ePartnerConfigProperties.EPCP_DEFAULT_BET_INDEX)
    ) {
      this.defaultBetIndex = data[ePartnerConfigProperties.EPCP_CURRENCIES_DATA][data[ePartnerConfigProperties.EPCP_CURRENCY]][ePartnerConfigProperties.EPCP_DEFAULT_BET_INDEX];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_CANCEL_ENABLED)) {
      this.cancelEnabled = data[ePartnerConfigProperties.EPCP_CANCEL_ENABLED];
    }

    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_RULES_ON_START)) {
      this.showRulesOnStart = data[ePartnerConfigProperties.EPCP_SHOW_RULES_ON_START];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_DISABLE_AUTO_PLAY)) {
      this.disableAutoplay = data[ePartnerConfigProperties.EPCP_DISABLE_AUTO_PLAY];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SKIP_BLOCKED)) {
      this.skipBlocked = data[ePartnerConfigProperties.EPCP_SKIP_BLOCKED];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_ROUND_DURATION)) {
      this.roundDuration = data[ePartnerConfigProperties.EPCP_ROUND_DURATION];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_OPERATOR_BETS_IDS)) {
      this.showOperatorBetsIds = data[ePartnerConfigProperties.EPCP_SHOW_OPERATOR_BETS_IDS];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_DISABLE_BUY_BONUS)) {
      this.disableBuyBonus = data[ePartnerConfigProperties.EPCP_DISABLE_BUY_BONUS];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_DISABLE_SPIN_BY_SPACE)) {
      this.disableSpinBySpace = data[ePartnerConfigProperties.EPCP_DISABLE_SPIN_BY_SPACE];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SESSION)) {
      this.session = data[ePartnerConfigProperties.EPCP_SESSION];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_GAME_CONFIG)) {
      this.gameConfig = data[ePartnerConfigProperties.EPCP_GAME_CONFIG];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_MESSAGE_EVENTS)) {
      this.messageEvents = data[ePartnerConfigProperties.EPCP_MESSAGE_EVENTS];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_DISPLAY_RTP)) {
      this.infoPanel.displayRtp = data[ePartnerConfigProperties.EPCP_DISPLAY_RTP];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_NET_POSITION)) {
      this.infoPanel.showNetPosition = data[ePartnerConfigProperties.EPCP_SHOW_NET_POSITION];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_GAME_STATE)) {
      this.infoPanel.showGameState = data[ePartnerConfigProperties.EPCP_SHOW_GAME_STATE];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_STOP_WATCH)) {
      this.infoPanel.showStopwatch = data[ePartnerConfigProperties.EPCP_SHOW_STOP_WATCH];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_SHOW_CLOCK)) {
      this.infoPanel.showClock = data[ePartnerConfigProperties.EPCP_SHOW_CLOCK];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_CERTIFICATION_NAME)) {
      this.certificationName = data[ePartnerConfigProperties.EPCP_CERTIFICATION_NAME];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_ENABLE_REALITY_CHECK)) {
      this.enableRealityCheck = data[ePartnerConfigProperties.EPCP_ENABLE_REALITY_CHECK];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_LIGHT_MODE)) {
      this.lightMode = data[ePartnerConfigProperties.EPCP_LIGHT_MODE];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_HOME_BUTTON_HISTORY_BACK_DISABLE)) {
      this.homeButtonHistoryBackDisable = data[ePartnerConfigProperties.EPCP_HOME_BUTTON_HISTORY_BACK_DISABLE];
    }
    if (data.hasOwnProperty(ePartnerConfigProperties.EPCP_IS_MAX_BETS_COUNT_ONE)) {
      this.isMaxBetsCountOne = data[ePartnerConfigProperties.EPCP_IS_MAX_BETS_COUNT_ONE];
    }
  }
}
