import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import eStateTypes from './enums/eStateTypes'
import { gsap } from 'gsap';
import './style.css';


export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.uiContainer = null;

    this.addListeners();
  }

  onGameResized(data) {
    let halfGameWidth = this.backgroundContainer.displayData.width / 2;
    let halfGameHeight = this.backgroundContainer.displayData.height / 2;
    halfGameHeight -= data.isMobile ? 640 : 0;

    let mainPivotX;
    let mainPivotY;

    if (data.isLandscape) {
      this.slotContainer.anchorDefault();
      this.slotContainer.scale.set(this.slotContainer.displayData.scaleX);
      mainPivotX = halfGameWidth + 300;
      mainPivotY = halfGameHeight - 50;
    } else if (data.isMobile && data.isPortrait) {
      this.slotContainer.pivot.x = this.slotContainer.displayData.width / 2;
      this.slotContainer.x = this.backgroundContainer.displayData.width / 2 + 5;
      this.slotContainer.y = this.slotContainer.displayData.y + 10;
      this.slotContainer.scale.set(0.92);
      mainPivotX = halfGameWidth + 100;
      mainPivotY = halfGameHeight - 50;
    }

    this.pivot.x = mainPivotX;
    this.pivot.y = mainPivotY;
    this.x = mainPivotX;
    this.y = mainPivotY;
  }

  init() {
    this.displayData.x = this.x;
    this.displayData.y = this.y;
    this.winningsController = new BaseSlotWinningsController({nextWinTimeout: 2000, nextScatterWinTimeout: 3000});

    this.slotContainer = this.getChildByName('SlotWrapper');
    this.slot = this.slotContainer.getChildByName('slotContainer');
    this.reelsContainer = this.slot.getChildByName('reelsContainer');

    this.jackpotGameContainer = this.jackpotGame.getChildByName('jackpotGame');
    this.popupWonJackpotGame = this.jackpotGame.getChildByName('popupWonJackpotGame');
    this.freespinsStart = this.getChildByName('freespinsStart');
    this.freespinsEnd = this.getChildByName('freespinsEnd');

    // this.linesContainer = this.slotContainer.getChildByName('LinesContainer');

    // this.nearWinContainer = this.slot_container.getChildByName('NearWinContainer');
    // this.lineWinningContainer = this.slot_container.getChildByName('LineWinningContainer');

    this.backgroundContainer = this.getChildByName('backgroundContainer');
    // this.bigWinContainer = this.getChildByName('BigWinContainer');
    // this.purchaseContainer = this.getChildByName('PurchaseContainer');
    //this.linesContainer.showLines(GameModel.lines);
  }

  addListeners() {
    GlobalDispatcher.add('model:linesChanged', this.onLinesChanged, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add('stateEnd:initState', this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
    GlobalDispatcher.add('antisipator: start', this.onStartAntisipator, this);
    GlobalDispatcher.add('antisipator: stop', this.onStopAntisipator, this);

    // GlobalDispatcher.add('model:getServerData', this.checkForFreeSpins, this);
  }

  onStartAntisipator() {
    gsap.to(this.scale, { x: 1.03, y: 1.03, duration: 2.5 });
  }

  onStopAntisipator() {
    gsap.to(this.scale, { x: 1, y: 1, duration: 0.2 });
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    // if (!EntryPoint.GameModel.isFreeSpinsMode) return;
    // this.backgroundContainer.showFreeSpinsBack();
    // SoundManager.pause(sounds.BACKGROUND.name);
    // SoundManager.play(sounds.FREE_SPINS_BACK.name, sounds.FREE_SPINS_BACK.volume, true, 'music');
  }

  onStateChanged(data) {
    switch (data.params) {
      case eStateTypes.EBST_INIT:
        break;
      case eStateTypes.EBST_WAIT_USER_INTERACTION:
        break;
      case eStateTypes.EBST_IDLE:
        break;
      case eStateTypes.EST_SHOW_WON_JACKPOT:
        this.popupWonJackpotGame.show();
        break;
      case eStateTypes.EST_JACKPOT_GAME_IN:
        this.popupWonJackpotGame.hide();
        this.jackpotGameContainer.show();
        break;
      case eStateTypes.EST_JACKPOT_GAME_OUT:
        this.jackpotGameContainer.hide();
        break;
      case eStateTypes.EST_BONUS_GAME_IN:
        this.freespinsStart.show();
        break;
      case eStateTypes.EST_BONUS_GAME_OUT:
        this.freespinsEnd.show();
        break;
      case eStateTypes.EBST_REQUEST_ROLLING:
        this.reelsContainer.start();
        this.winningsController.reset();
        // this.linesContainer.hideLines();
        // this.linesContainer.reset();
        // this.symbolsAnimationsContainer.removeAnimations();
        // this.lineWinningContainer.hide();
        break;
      case eStateTypes.EBST_STOP_ROLLING:
        this.reelsContainer.stop(GameModel.reels);
        break;
      case eStateTypes.EST_SHOW_WINNINGS:
        this.winningsController.start();
        break;
    }
  }
}
