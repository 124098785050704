import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class flyValueState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('fly: complete', this.onFlyComplete, this)
  }

  onFlyComplete() {
      if(this.active) {
        this.complete();
      }
  }
}
