import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class ShowWinJackpotGameSymbolState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('animationWinJackpotGameSymbol: complete', this.onWinJackpotGameSymbol, this)
    }

    onWinJackpotGameSymbol() {
        if(this.active) {
            this.complete();
        }
    }
}
