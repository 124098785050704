import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eServerClientStone from './eServerClientStone';
import SoundManager from 'Engine/soundManager/SoundManager';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../../animations/animationTypes';
import { gsap } from 'gsap';
import { eSounds } from '../../enums/eSoundTypes';

export default class ControllerJackpotGamePlate extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
    this.initAnimation();
    this.onClick = this.onClick.bind(this);
    this.addListeners();
  }

  init() {
    this.goldenPlate = this.getChildByName('goldenPlate');
    this.stones = this.getChildByName('stones');
    this.goldenPlate.interactive = true;
    this.goldenPlate.buttonMode = true;
  }

  addListeners() {
    this.goldenPlate.on('pointertap', this.onClick);
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(animationTypes.JACKPOT);
    this.animation.visible = false;
    this.animation.x = this.goldenPlate.displayData.width / 2;
    this.animation.y = this.goldenPlate.displayData.height / 2;
    this.animation.state.addListener({
      complete: () => {
        this.animation.visible = false;
        this.startAnimationMove();
      },
    });
    this.addChild(this.animation);
  }

  startAnimation() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, 'lid_disappear', false);
  }

  startAnimationMove() {
    const microIcon = this.panels.panels[eServerClientStone[this.stoneIndex]].getNextIconContainer();
    GlobalDispatcher.dispatch('plate: startAnimationMove', {
      stone: this.stone,
      microIcon,
      stoneIndex: this.stoneIndex,
    });
  }

  _open({ index: stoneIndex }) {
    this._stopShake();
    if (!stoneIndex && stoneIndex !== 0) return;
    this.stoneIndex = stoneIndex;
    SoundManager.play(eSounds.EST_JACKPOT_GAME_PLATE_DESTROYED.soundName, eSounds.EST_JACKPOT_GAME_PLATE_DESTROYED.volume, false, eSounds.EST_JACKPOT_GAME_PLATE_DESTROYED.group);
    this.openByType(eServerClientStone[stoneIndex]);
    this.startAnimation();
  }

  openByType(type) {
    this.stones.setStone(type);
    this.stone = this.stones.stones[type].children[1];
    this.goldenPlate.visible = false;
  }

  closePlate() {
    this.goldenPlate.visible = true;
  }

  onClick() {
    this._shake();
    this.goldenPlate.off('pointertap', this.onClick);
    GlobalDispatcher.dispatch('jackpotGame: addOpeningPlateToQueue', {
      position: { x: this.colId, y: this.id },
      openFunc: this._open.bind(this),
      stopShakeFunc: this._stopShake.bind(this),
    });
    SoundManager.play(eSounds.EST_JACKPOT_GAME_CLICK_ON_PLATE.soundName, eSounds.EST_JACKPOT_GAME_CLICK_ON_PLATE.volume, false, eSounds.EST_JACKPOT_GAME_CLICK_ON_PLATE.group);
  }

  _shake() {
    const timeline = gsap.timeline({ paused: true, repeat: -1 });
    timeline.to(this, { x: '+=5', yoyo: true, repeat: 5, duration: 0.05 }, 0);
    timeline.to(this, { y: '+=5', yoyo: true, repeat: 7, duration: 0.03 }, 0);
    timeline.to(
      this,
      { x: '-=5', yoyo: true, repeat: 5, duration: 0.05 },
      0.05
    );
    timeline.to(
      this,
      { y: '-=5', yoyo: true, repeat: 7, duration: 0.03 },
      0.03
    );
    timeline.play();
  }

  _stopShake() {
    gsap.killTweensOf(this);
    gsap.to(this, {
      x: this.displayData.x,
      y: this.displayData.y,
      duration: 0.05,
    });
    this.goldenPlate.on('pointertap', this.onClick);
  }
}
