import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class ShowStartScreenState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  start() {
    super.start();
    GlobalDispatcher.dispatch('startScreen:show');
    GlobalDispatcher.add('startScreen:hide', this.stop.bind(this));
    if (EntryPoint.GameModel.isBonusGameMode) {
      OPWrapperService.infoPanel.setGameState(eGameStateTypes.EGST_BONUS_GAME);

      EntryPoint.GameModel.bonusGameEnabled = true;
    }

  }

  stop() {
    if (!this.active) return;
    this.complete();
  }

  onEnd() {
    super.onEnd();
  }
}
