const uiColors = {
  overlay: {
    default: 'rgba(38, 0, 48, 0.9)',
  },
  primary: {
    default: 'rgba(129, 12, 201, 0.6)',
    hover: '#A209E2',
    pressed: '#4C0077',
    disabled: 'rgba(159, 114, 185, 0.6)',
  },
  secondary: {
    default: 'rgba(226, 9, 192, 0.6)',
    hover: '#FF41E1',
    pressed: '#9A0587',
  },
  toggled: {
    default: '#A209E2',
  },
  panel: {
    default: 'rgba(108, 2, 111, 0.3)',
  }
};

const uiConfig = {
  H: {
    colors: uiColors,
    offsets: {
      right_panel: { top: 0, left: -11 },
      bottom_panel: { top: 0, left: 0 },
    },
  },

  V: {
    colors: uiColors,
    offsets: {
      top: 0,
      left: 0,
      tournaments_panel: {
        top: -90,
        left: -580,
      }
    },
  }
};

export {
  uiConfig,
}
