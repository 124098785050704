import GameModel from "../model/GameModel";
import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
    parseInit(data) {
        let newData = {};
        newData.action = 'init';
        newData.balance = data.balance;
        newData.params = {
            betPossible: data.bets_available
        };
        newData.current = {
            bet: data.bet_amount,
            lines: 5
        };

        if (data.isJackpot) {
            newData.jackpotGame = {
                isJackpotGame: data.isJackpot,
                field: data.jackpot.field,
                results: data.jackpot.progress
            }
        }

        newData.currencyInfo = {
            decimals: data.decimals,
            symbol: data.currency,
            currency: data.currency
        };

        newData.paytableInfo = data.slot_machine.paytable;

        newData.jackpotCoeffs = data.jackpots_coeffs;

        newData.bonusGameData = this.makeBonusGameData(data);

        newData.progresesToJackpot = data.jack_potter_progress;

        newData.freebets = data.freebets

        return newData;
    }

    parseSpin(data) {
        let newData = {};
        newData.action = 'spin';
        newData.balance = data.balance;
        newData.spin = {
            reels: data.spin?.reels || data.bonus.reels,
            winnings: this.makeWinnings(data),
            bet: data.bet_amount,
        };

        newData.jackpotGame = {
            isJackpotGame: data.isJackpot,
        }

        newData.jackpotCoeffs = data.jackpots_coeffs;
        newData.progresesToJackpot = data.jack_potter_progress;

        newData.bonusGameData = this.makeBonusGameData(data);

        newData.freebets = data.freebets

        return newData;
    }

    parseJackpotPlaceOpen(data) {
        let newData = {};

        newData.action = 'jackpot_step';
        newData.balance = data.balance;

        newData.jackpotGame = {
            isJackpotGame: !(typeof(data.winJackpotType) === 'number' && data.winJackpotType >= 0),
            isJackpotWin: typeof(data.winJackpotType) === 'number' && data.winJackpotType >= 0,
            winJackpotType: data.winJackpotType,
            jackpotWinAmount: data.winAmount,
            jackpotMultiplier: data.jackpotMultiplier,
        }

        newData.bonusGameData = this.makeBonusGameData(data);

        newData.freebets = data.freebets

        return newData;
    }

    makeWinnings( data) {
        const spinData = data.spin;
        let winnings = {};
        winnings.totalWin = 0;

            winnings.lines = [];
        console.log(EntryPoint.configData.payLines);
        if (spinData) {
            for (const lineNumber in spinData.win_lines) {
                const lineLeng = spinData.lines_icons[lineNumber];
                if(lineLeng < 3) continue;
                let line = {};
                line.path = EntryPoint.configData.payLines[lineNumber - 1].concat().splice(0, lineLeng);
                // line.line = this.linesRelation[winData.payline.number];
                line.line = lineNumber - 1;
                line.count = lineLeng;
                line.win = spinData.win_lines[lineNumber] * data.bet_amount / 20;

                winnings.lines.push(line);

                winnings.totalWin = spinData.total_win;
            }
        }

        if (data.jokers_positions?.length) {
            winnings.jackpotters = [];
            let jackpotter = {};
            jackpotter.symbol = 1;
            const pathArr = new Array(3);
            data.jokers_positions.forEach(reelData => {
                pathArr[reelData[0]] = reelData[1]+1;
            })
            jackpotter.path = pathArr;
            jackpotter.count = data.jokers_positions.length;
            // scatter.win = data.scatters_win;
            jackpotter.isScatter = true;
            winnings.jackpotters.push(jackpotter);

            // winnings.totalWin += scatter.win;
        }
        if (data.jokers_positions?.length === 3) winnings.scatters = winnings.jackpotters;



        return winnings;
    }

    makeBonusGameData(data) {
        if (!data.bonus) {
            return null;
        }
        const bonusGameData = {
            totalWinPerSpin: data.total_win,
            totalWin: data.bonus.total_win,
            respins: data.bonus.respins,
            bonusGameTriggered: data.bonus.is_bonus_triggered,
            isBonusGame: data.bonus.is_bonus,
            coinSymbolsValues: data.bonus.payoutsList[0] || data.bonus.payouts,
            coinSymbolsValuesList: data.bonus.payoutsList,
            bonusGameAnimatonsPaths: data.bonus.animations,
            bonusGameAvalibleReels: data.bonus.default_reels
        };
        return bonusGameData;
    }
}
