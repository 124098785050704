import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../animations/animationTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class BackgroundContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    // this.createTweens();
    this.initAnimation();
    this.active = false;
    this.addListeners();
  }

  init() {
    // this.freeSpinsBackground = this.getChildByName('freeSpinsBackground');
    // this.freeSpinsBackground.visible = false;
    this.background = this.getChildByName('background');
  }
  addListeners() {
    GlobalDispatcher.add(
      'stateStart:jackpotGameInState',
      this.hideTemporarily,
      this
    );
    GlobalDispatcher.add('stateStart:jackpotGameOutState', this.tryShow, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize(data) {
    if (data.isLandscape) {
      this.background.goToFrame(2);
      if (!this.isJackpotGame) this.animation.visible = true;
      this.isPortrait = false;
      this.animation.state.setAnimation(0, 'bg_coins_joker_x_mas', true);
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
      this.isPortrait = true;
      this.animation.state.setAnimation(0, 'bg_coins_joker_x_mas_mob', true);
    }
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation(
      animationTypes.BACKGROUND
    );
    this.animation.state.setAnimation(0, 'bg_coins_joker_x_mas', true);
    this.getChildByName('animationContainer').addChild(this.animation);
  }

  hideTemporarily() {
    setTimeout(() => {
      this.isJackpotGame = true;
      this.animation.visible = false;
    }, 2000);
  }

  tryShow() {
    this.isJackpotGame = false;
    if (!this.isPortrait) this.animation.visible = true;
  }
}
