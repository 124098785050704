import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import SpinTimeManager from "Engine/base/spinTime/SpinTimeManager";
import ServerManager from '../server/ServerManager';

export default class RequestRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);

        this.startTime = 0;
    }

    addListeners() {
        super.addListeners();
    }

    async start() {
        super.start();
        SpinTimeManager.start();
        this.startTime = Date.now();
        EntryPoint.GameSettings.quickStop = false;
        if (!EntryPoint.GameModel.isBonusGameMode) {
            OPWrapperService.freeBetsController.decreaseFreeBets();
            OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
        }
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        await ServerManager.onStartSpin();
        this.complete();
    }

    onEnd() {
        super.onEnd();
    }
    stop() {
        EntryPoint.GameSettings.quickStop = true;
    }
}
