import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import { shadowText } from 'Engine/utils/fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';

export default class InfoPopupContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;
    this.localizationManager = window.OPWrapperService.localizations

    this.init();
    this.initButtons();
    this.addListeners();
    this.addActions();

    this.hide();
  }

  init() {
    this.getChildAt(0).alpha = 0.9;
    this.wrapper = this.getChildByName('wrapper');
    this.titleText = this.wrapper.getChildByName('titleText');
    this.messageText = this.wrapper.getChildByName('messageText');
  }

  initButtons() {
    switch (this.wrapper.currentFrameIndex) {
      case 1:
        this.button1 = this.wrapper.getChildByName('okButton');
        makeHitArea(this.button1);
        this.button1.enabled = true;

        this.btnOkLabel = this.button1.getChildByName('info_btn_ok_label');
        this.btnOkLabel.text = this.localizationManager.getLocalizedText(this.btnOkLabel.name);
        this.btnOkLabel.style = { ...this.btnOkLabel.style, ...shadowText };
        break;
      case 2:
        this.button1 = this.wrapper.getChildByName('noButton');
        this.button2 = this.wrapper.getChildByName('yesButton');
        makeHitArea(this.button1);
        makeHitArea(this.button2);
        this.button1.enabled = true;
        this.button2.enabled = true;

        this.btnNoLabel = this.button1.getChildByName('info_btn_no_label');
        this.btnNoLabel.text = this.localizationManager.getLocalizedText(this.btnNoLabel.name);
        this.btnNoLabel.style = { ...this.btnNoLabel.style, ...shadowText };

        this.btnYesLabel = this.button2.getChildByName('info_btn_yes_label');
        this.btnYesLabel.text = this.localizationManager.getLocalizedText(this.btnYesLabel.name);
        this.btnYesLabel.style = { ...this.btnYesLabel.style, ...shadowText };
        break;
    }

    this.btnClose = this.wrapper.getChildByName('btnClose');
    makeHitArea(this.btnClose);
    this.btnClose.enabled = true;
  }

  addListeners() {
    GlobalDispatcher.add('infoPopupManager:showError', this.onShowError, this);
    //GlobalDispatcher.add('model:noMoney', this.onNoMoney, this);
  }

  addActions(data) {
    switch (this.wrapper.currentFrameIndex) {
      case 1:
        this.button1.on('pointertap', () => this.onButtonClick(data ? data.button1 || null : null), this);
        break;
      case 2:
        this.button1.on('pointertap', () => this.onButtonClick(data ? data.button1 || null : null), this);
        this.button2.on('pointertap', () => this.onButtonClick(data ? data.button2 || null : null), this);
        break;
    }

    this.btnClose.on('pointertap', this.hide, this);
  }

  onShowError(event) {
    let data = event.params;
    this.wrapper.goToFrame(data.type);

    this.titleText.text = this.localizationManager.getLocalizedText(data.title);
    this.messageText.correctPosition(null, 'center');
    this.messageText.text = this.localizationManager.getLocalizedText(data.message);
    while (this.messageText.origHeight > this.messageText.height) {
      this.messageText.style.fontSize--;
    }
    this.messageText.correctPosition(null, 'center');

    this.initButtons();
    this.addActions(data);

    this.show();
  }

  onButtonClick(params) {
    if (params && params.callback) {
      params.callback.call(params.context, params.params);
    }
    this.hide();
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
