export default {
  ESI_WILD: 1,
  ESI_BELL: 2,
  ESI_WATERMELON: 3,
  ESI_GRAPE: 4,
  ESI_ORANGE: 5,
  ESI_LEMON: 6,
  ESI_PLUM: 7,
  ESI_CHERRY: 8,
  ESI_JOKER: 9,
  ESI_COIN: 10,
  ESI_BONUS_JOKER: 11,
  ESI_CROWN: 12,
}
