import GameModel from '../model/GameModel';
import InitState from 'Engine/states/InitState';
import IdleState from 'Engine/states/IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowWinningsState from './ShowWinningsState';
import ShowWinState from './ShowWinState';
import ShowLinesWinState from './ShowLinesWinState';
import MinimalRollingState from './MinimalRollingState';
import EntryPoint from 'Engine/EntryPoint';
import ShowStartScreenState from './showStartScreenState';
import JackpotGameInState from './JackpotGameInState';
import JackpotGameIdleState from './JackpotGameIdleState';
import JackpotGameOutState from './JackpotGameOutState';
import ShowWonJackpotGameState from './ShowWonJackpotGameState';
import StopWinState from './StopWinState';
import bonusGameOutState from './bonusGameOutState';
import bonusGameInState from './bonusGameInState';
import WaitUserInteractionState from './WaitUserInteractionState';
import AnimationJackpotterState from './AnimationJackpotterState';
import flyValueState from './flyValueState';
import ShowWinJackpotGameSymbolState from './ShowWinJackpotGameSymbolState';
import eStateTypes from '../enums/eStateTypes';

export const StatesConfig = [
  {
    name: eStateTypes.EBST_INIT, class: InitState, transitions: [
      {
        to: eStateTypes.EBST_START_SCREEN, condition: function () {
          return true;
        }
      }
    ], entry: true
  },
  {
    name: eStateTypes.EBST_IDLE, class: IdleState, transitions: [
      {
        to: eStateTypes.EBST_REQUEST_ROLLING, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EBST_REQUEST_ROLLING, class: RequestRollingState, transitions: [
      {
        to: eStateTypes.EBST_MINIMAL_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EBST_MINIMAL_ROLLING, class: MinimalRollingState, transitions: [
      {
        to: eStateTypes.EBST_STOP_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WON_JACKPOT, class: ShowWonJackpotGameState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_IN, class: JackpotGameInState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_IDLE, class: JackpotGameIdleState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_OUT, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_OUT, class: JackpotGameOutState, transitions: [
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0

        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, class: ShowWinJackpotGameSymbolState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WON_JACKPOT, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
    ]
  },
  {
    name: eStateTypes.EBST_STOP_ROLLING, class: StopRollingState, transitions: [
      {
        to: eStateTypes.EST_ANIMATION_JACKPOTTER, condition: function () {
          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.bonusGameAnimatonsPaths?.length
            // && !(GameModel.winnings.lines && GameModel.winnings.lines.length > 0) && (EntryPoint.AutoPlaySettings.active );
        }
      },
      {
        to: eStateTypes.EST_FLY_VALUE, condition: function () {
          return EntryPoint.GameModel.bonusGameEnabled
            && ((GameModel.coinSymbolsValues?.length) || EntryPoint.GameModel.winnings?.jackpotters?.length)
        }
      },
      {
        to: eStateTypes.EST_SHOW_LINES_WIN, condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0

        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_LINES_WIN, class: ShowLinesWinState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WINNINGS, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EBST_SHOW_WIN, class: ShowWinState, transitions: [
      {
        to: eStateTypes.EBST_STOP_WIN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EBST_STOP_WIN, class: StopWinState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0

        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WINNINGS, class: ShowWinningsState, transitions: [
      {
        to: eStateTypes.EBST_SHOW_WIN, condition: function () {
          return GameModel.totalWin > GameModel.bet;
        }
      },
      {
        to: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },

  {
    name: eStateTypes.EBST_START_SCREEN, class: ShowStartScreenState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IN, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_BONUS_GAME_IN, class: bonusGameInState, transitions: [
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_BONUS_GAME_OUT, class: bonusGameOutState, transitions: [
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },

  {
    name: eStateTypes.EBST_WAIT_USER_INTERACTION, class: WaitUserInteractionState, transitions: [
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_ANIMATION_JACKPOTTER, class: AnimationJackpotterState, transitions: [
      {
        to: eStateTypes.EST_FLY_VALUE, condition: function () {
          return EntryPoint.GameModel.bonusGameEnabled
            && ((GameModel.coinSymbolsValues?.length) || EntryPoint.GameModel.winnings?.jackpotters?.length)
        }
      },
      {
        to: eStateTypes.EST_SHOW_LINES_WIN, condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0

        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FLY_VALUE, class: flyValueState, transitions: [
      {
        to: eStateTypes.EBST_SHOW_WIN, condition: function () {
          return GameModel.winnings && (GameModel.winnings.lines && GameModel.winnings.lines.length > 0)
        }
      },
      {
        to: eStateTypes.EST_SHOW_WIN_JACKPOT_GAME_SYMBOL, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_OUT, condition: function () {

          return EntryPoint.GameModel.bonusGameEnabled && EntryPoint.GameModel.respins <= 0

        }
      },
      {
        to: eStateTypes.EST_BONUS_GAME_IN, condition: function () {
          return EntryPoint.GameModel.bonusGameTriggered ;
        }
      },
      {
        to: eStateTypes.EBST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
];
