import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class JackpotGameOutState extends BaseState {

    constructor(stateData) {
        super(stateData);

    }

    start() {
        super.start();
        if (this.active)
            this.complete();
    }

    onEnd() {
        super.onEnd();
        const state =  EntryPoint.GameModel.bonusGameEnabled ? eGameStateTypes.EGST_BONUS_GAME : eGameStateTypes.EGST_REGULAR;
        OPWrapperService.infoPanel.setGameState(state);
    }
}
