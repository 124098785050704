import BaseState from "Engine/base/states/BaseState";

export default class JackpotGameInState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    start() {
        super.start();
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        if (this.active)
            this.complete();
    }

}
