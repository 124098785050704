const eSounds = {
  EST_BACKGROUND: { soundName: 'backgroundSound', volume: 0.5, group: 'music' },
  EST_BIG_WIN: { soundName: 'BigWin', volume: 0.8, group: 'winning' },
  EST_MEGA_WIN: { soundName: 'SuperWin', volume: 0.9, group: 'winning' },
  EST_SIMPLE_WIN_0: { soundName: 'simpleWin_0', volume: 0.5, group: 'winning' },
  EST_SIMPLE_WIN_1: { soundName: 'simpleWin_1', volume: 0.5, group: 'winning' },
  EST_SIMPLE_WIN_2: { soundName: 'simpleWin_2', volume: 0.5, group: 'winning' },
  EST_SIMPLE_WIN_3: { soundName: 'simpleWin_3', volume: 0.5, group: 'winning' },
  EST_SIMPLE_WIN_4: { soundName: 'simpleWin_4', volume: 0.5, group: 'winning' },
  EST_FS_TOTAL_WIN: { soundName: 'total_win', volume: 0.5, group: 'winning' },
  EST_MONEY_GAIN: {
    soundName: 'MoneyGain',
    volume: 0.5,
    loop: true,
    group: 'winning',
  },
  EST_BUTTON_CLICK: {
    soundName: 'ButtonClick',
    volume: 0.7,
    group: 'default',
  },
  EST_REEL_STOP: { soundName: 'ReelStop1', volume: 0.5, group: 'default' },
  EST_SCATTER_STOP: { soundName: 'ScatterStop', volume: 0.3, group: 'default' },
  EST_TILE: { soundName: 'Tile', volume: 0.35, group: 'winning' },
  EST_REEL_ANTISIPATOR: { soundName: 'antisip_reel_start', volume: 0.6, group: 'winning' },
  EST_REEL_ANTISIPATOR_TILE: { soundName: 'antisip_reels_finish', volume: 0.6, group: 'winning' },
  EST_START_BOUNS_GAME: { soundName: 'startBounsGame', volume: 0.5, group: 'winning' },
  EST_JOKER_WITH_TIGER: { soundName: 'jokerWithTiger', volume: 0.5, group: 'winning' },
  EST_FLY_COIN_SYMBOL: { soundName: 'flyCoinSymbol', volume: 0.5, group: 'winning' },
  EST_FLY_COIN_VALUE: { soundName: 'flyCoinValue', volume: 0.5, group: 'winning' },
  EST_JACKPOT_GAME_BACKGROUND: { soundName: 'jackpotGame/background', volume: 0.9, group: 'music' },
  EST_WIN_JACKPOT_GAME: { soundName: 'jackpotGame/winJackpotGame', volume: 0.7, group: 'winning' },
  EST_JACKPOT_GAME_CLICK_ON_PLATE: { soundName: 'jackpotGame/clickOnPlate', volume: 0.5, group: 'default' },
  EST_JACKPOT_GAME_JACKPOT: { soundName: 'jackpotGame/jackpot', volume: 0.7, group:  'winning' },
  EST_JACKPOT_GAME_PLATE_DESTROYED: { soundName: 'jackpotGame/plateDestroyed', volume: 0.5, group:  'default' },
};

const soundsPreloaderConfig = Object.fromEntries(
  Object.keys(eSounds).map((key) => [key, eSounds[key].soundName])
);

export { eSounds, soundsPreloaderConfig };
