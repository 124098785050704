import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eStateTypes from '../enums/eStateTypes'

export default class UiAdapterExtended extends UiAdapter {

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('stateEnd:bonusGameInState', this.showUi, this);
    GlobalDispatcher.add('stateEnd:bonusGameOutState', this.showUi, this);
  }

  extraStateHandlers = {
   [eStateTypes.EBST_IDLE]: () => {
      this.ControllerUi.toggleSpinButton(true);
    },
    [eStateTypes.EST_SHOW_WON_JACKPOT]: () => {
      this.hideUi();
    },
    [eStateTypes.EST_JACKPOT_GAME_IDLE]: () => {
      this.hideUi();
    },
    [eStateTypes.EST_JACKPOT_GAME_OUT]: () => {
      this.showUi();
      this.ControllerUi.setWin(EntryPoint.GameModel.totalWin + EntryPoint.GameModel.jackpotWinAmount);
    },
    [eStateTypes.EST_BONUS_GAME_IN]: () => {
      this.hideUi();
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.toggleSpinButton(false);

      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
      this.ControllerUi.showFreeSpins(EntryPoint.GameModel.freespins, this._bonusLabelText);
      this.ControllerUi.toggleInfoButton(false);
      this.ControllerUi.toggleTournamentsLabel(false);
      this.ControllerUi.showTotalWin();
    },
    [eStateTypes.EST_BONUS_GAME_OUT]: () => {
      this.hideUi();
      this.ControllerUi.showStopButton();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.toggleSpinButton(false);

      this.ControllerUi.toggleMaxBetButton(false);
      this.ControllerUi.toggleBetSelectors(false);
      this.ControllerUi.toggleInfoButton(false);
      this.ControllerUi.toggleTournamentsLabel(false);
      this.ControllerUi.setTotalWin(EntryPoint.GameModel.freespinsTotalWin);
      this.ControllerUi.hideFreeSpins();
      this.ControllerUi.hideTotalWin();
    },
    [eStateTypes.EST_SHOW_WINNINGS]: () => {
      this.ControllerUi.toggleInfoButton(false);
    },
    [eStateTypes.EST_FLY_VALUE]: () => {
      this.ControllerUi.toggleInfoButton(false);
    },
    [eStateTypes.EST_ANIMATION_JACKPOTTER]: () => {
      this.ControllerUi.toggleInfoButton(false);
    },
    default: () => {
    },
  };
}
